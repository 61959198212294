import axios from 'axios'
import msalInstance from "@/msal/msal";

// 'https://genconservice.azurewebsites.net/api/'
const instance = axios.create({
    baseURL: 'https://genconservice.azurewebsites.net/api/',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
})


instance.interceptors.request.use(
    config => {
      const token = sessionStorage.getItem("access_token");
      if (token) {
        config.headers.common["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);

instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
        const scopes = ['api://11de27bd-a9ba-4589-8a75-6ce91c043d08/access_as_user']
        await msalInstance.loginPopup({ scopes })
          .then(async function() {
              await msalInstance.acquireTokenSilent({scopes})
                  .then(res => {
                      sessionStorage.setItem('access_token', res.accessToken);
                  })
          })
        window.location.replace('/')
    }

    return Promise.reject(error);
});

export default instance

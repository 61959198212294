import * as Msal from 'msal';

const msalConfig = {
    auth: {
        clientId: 'ff616b67-cb02-4bb8-8590-d702cc2e8e0b',
        redirectUri: 'https://gencon.bartwest.com'
    }
};

const msalInstance = new Msal.UserAgentApplication(msalConfig);

export default msalInstance